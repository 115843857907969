<template>
  <el-main>
    <ics-dialog-inner :loading="loading.submit" :visible.sync="uploadDialog.visible" title="导入白名单" submit-title="上传" cancel-title="关闭" class="form-dialog" width="40%" @submit="confirmUpdate" @close="close">
      <ics-page-inner title="" :show-header="true">
        <el-form ref="uploadForm" :model="formInfo" :rules="rules" label-width="140px" label-position="left" :label-suffix="constants.labelSuffix">
          <div class="upload-inner" style="width: 90%; top: 50%; left: 50%; margin-left: 30px;">
            <fero-upload
              ref="upload"
              v-model="formInfo.fileList"
              :limit="limit"
              :action="uploadUrl"
              drag
              accept=".xlsx,.xls"
              :auto-upload="false"
              :headers="headers"
              :before-upload="beforeUploadFile"
              :on-success="onSuccessFile"
              :on-preview="previewFile"
              :on-error="onErrorFile"
              :on-change="onChange"
              :on-remove="onChange"
              :on-exceed="onExceed"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__text">
                支持扩展名：.xlsx .xls文件
              </div>
            </fero-upload>
          </div>
          <el-row :gutter="80">
            <el-col :span="12">
              <el-form-item label="白名单模板">
                <p><a href="/template/白名单导入模板.xlsx">模版下载</a></p>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </ics-page-inner>
    </ics-dialog-inner>
  </el-main>
</template>

<script>
import IcsDialogInner from '@/components/dialog-inner'
import { routeEnterMixin } from '@/assets/js/mixins'
import cfca from '@/assets/js/cfca-prove'
import utils from '@/assets/js/utils'
class FormProp {
  fileList = []
}
export default {
  name: 'IcsUploadInner',
  components: { IcsDialogInner },
  mixins: [routeEnterMixin],
  props: {
    uploadDialog: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data () {
    return {
      limit: 1,
      fileUploadUrl: process.env.VUE_APP_REQUEST_BASE_URL + '/tenant/b/p/rick/importWhiteList',
      uploadUrl: '',
      headers: {
        Token: this.$store.state.token
      },
      formInfo: new FormProp(),
      rules: {
        fileList: [
          { required: true, message: '请上传文件' }
        ]
      },
      loading: {
        submit: false
      }
    }
  },
  methods: {
    confirmUpdate () {
      if (this._.isEmpty(this.formInfo.fileList)) {
        this.$message.error('请上传附件')
        return false
      }
      this.$refs.uploadForm.validate().then(() => {
        this.uploadUrl = this.fileUploadUrl
        this.$nextTick(() => {
          this.loading.submit = true
          this.$refs.upload.submit()
        })
      })
    },
    onChange (file, fileList) {
      this.formInfo.fileList = fileList
      this.$refs.uploadForm.validateField('fileList')
    },
    onExceed () {
      this.$message.error(`一次只能上传${this.limit}个文件`)
    },
    beforeUploadFile (file) {
      let fileName = file.name
      let pos = fileName.lastIndexOf('.')
      let lastName = fileName.substring(pos, fileName.length)
      if (lastName.toLowerCase() !== '.xls' && lastName.toLowerCase() !== '.xlsx') {
        this.$message.error("文件必须为.xls .xlsx类型")
        this.$refs.upload.clearFiles()
        this.loading.submit = false
        return false
      }
    },
    onSuccessFile (response, file, fileList) {
      this.$emit('onSuccessFile', response)
      this.$refs.upload.clearFiles()
      this.uploadDialog.visible = false
      this.loading.submit = false
    },
    onErrorFile (err, file, fileList) {
      const msg = JSON.parse(err.message)
      if (msg.message.indexOf('code') >= 0) {
        const data = JSON.parse(msg.message)
        console.log(data)
        if (data.code === 60000) {
          if (utils.getExplorer() === 'ie') {
            cfca.dataHunter(data.employeeCode, msg.path)
          } else {
            this.$message.warning('当前浏览器为：'+ utils.getExplorer() + ',请使用IE浏览器进行数字证书身份认证')
          }
        }
      } else {
        this.$message.error(msg.message || '上传失败')
      }
      this.formInfo.fileList = []
      this.loading.submit = false
      if (err.status === 401) {
        this.$store.commit('removeEmpInfo')
        window.location.href = process.env.VUE_APP_CONSOLE_DOMAIN + '/login?redirecturl=' + encodeURIComponent(window.location.href)
      }
    },
    previewFile (file) {
      this.utils.downloadFile(file.url, file.name)
    },
    close () {
      this.formInfo = new FormProp()
      this.$refs.upload.clearFiles()
      this.$refs.uploadForm.resetFields()
      this.loading.submit = false
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.dialog-footer{
  text-align: center;
}
</style>
